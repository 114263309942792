var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

(function (e) {

    var r = function r(e) {
        var t = e.h;
        if (!e.CORE_VERSION || !t.compareVersions(r.REQUIRE_CORE_VERSION, e.CORE_VERSION)) throw new Error("[MixItUp Multifilter] MixItUp Multifilter v" + r.EXTENSION_VERSION + " requires at least MixItUp v" + r.REQUIRE_CORE_VERSION);
        e.ConfigMultifilter = function () {
            this.enable = !1, this.logicWithinGroup = "or", this.logicBetweenGroups = "and", this.minSearchLength = 3, this.parseOn = "change", this.keyupThrottleDuration = 350, t.seal(this);
        }, e.Config.registerAction("beforeConstruct", "multifilter", function () {
            this.multifilter = new e.ConfigMultifilter();
        }), e.MultifilterFormEventTracker = function () {
            this.form = null, this.totalBound = 0, this.totalHandled = 0, t.seal(this);
        }, e.FilterGroupDom = function () {
            this.el = null, this.form = null, t.seal(this);
        }, e.FilterGroup = function () {
            this.dom = new e.FilterGroupDom(), this.activeSelectors = [], this.activeToggles = [], this.handler = null, this.mixer = null, this.logic = "or", this.parseOn = "change", this.keyupTimeout = -1, t.seal(this);
        }, t.extend(e.FilterGroup.prototype, {
            init: function init(e, t) {
                var n = this,
                    r = e.getAttribute("data-logic");
                n.dom.el = e, n.cacheDom(), n.dom.form && n.enableButtons(), n.mixer = t, (r && "and" === r.toLowerCase() || "and" === t.config.multifilter.logicWithinGroup) && (n.logic = "and"), n.bindEvents();
            },
            cacheDom: function cacheDom() {
                var e = this;  
                e.dom.form = t.closestParent(e.dom.el, "form", !0);
            },
            enableButtons: function enableButtons() {
                var e = this,
                    t = e.dom.form.querySelectorAll('button[type="submit"]:disabled'),
                    n = null,
                    r = -1;
                for (r = 0; n = t[r]; r++) {
                    n.disabled && (n.disabled = !1);
                }
            },
            bindEvents: function bindEvents() {
                var e = this;
                e.handler = function (n) {
                    switch (n.type) {
                        case "reset":
                        case "submit":
                            e.handleFormEvent(n);
                            break;
                        default:
                            e["handle" + t.pascalCase(n.type)](n);
                    }
                }, t.on(e.dom.el, "click", e.handler), t.on(e.dom.el, "change", e.handler), t.on(e.dom.el, "keyup", e.handler), e.dom.form && (t.on(e.dom.form, "reset", e.handler), t.on(e.dom.form, "submit", e.handler));
            },
            unbindEvents: function unbindEvents() {
                var e = this;
                t.off(e.dom.el, "click", e.handler), t.off(e.dom.el, "change", e.handler), t.off(e.dom.el, "keyup", e.handler), e.dom.form && (t.off(e.dom.form, "reset", e.handler), t.off(e.dom.form, "submit", e.handler)), e.handler = null;
            },
            handleClick: function handleClick(e) {
                var n = this,
                    r = t.closestParent(e.target, "[data-filter], [data-toggle]", !0),
                    i = -1,
                    s = "";
                r && (e.stopPropagation(), r.matches("[data-filter]") ? (s = r.getAttribute("data-filter"), n.activeSelectors = [s]) : r.matches("[data-toggle]") && (s = r.getAttribute("data-toggle"), (i = n.activeToggles.indexOf(s)) > -1 ? n.activeToggles.splice(i, 1) : n.activeToggles.push(s), n.activeSelectors = "and" === n.logic ? [n.activeToggles] : n.activeToggles), n.updateControls(), "change" === n.mixer.config.multifilter.parseOn && n.mixer.parseFilterGroups());
            },
            handleChange: function handleChange(e) {
                var t = this,
                    n = e.target;
                switch (e.stopPropagation(), n.type) {
                    case "text":
                    case "search":
                    case "password":
                    case "select-one":
                    case "radio":
                        t.getSingleValue(n);
                        break;
                    case "checkbox":
                    case "select-multiple":
                        t.getMultipleValues(n);
                }
                "change" === t.mixer.config.multifilter.parseOn && t.mixer.parseFilterGroups();
            },
            handleKeyup: function handleKeyup(e) {
                var t = this,
                    n = e.target;
                return "change" !== t.mixer.config.multifilter.parseOn ? void t.mixer.getSingleValue(n) : (clearTimeout(t.keyupTimeout), void (t.keyupTimeout = setTimeout(function () {
                    t.getSingleValue(n), t.mixer.parseFilterGroups();
                }, t.mixer.config.multifilter.keyupThrottleDuration)));
            },
            handleFormEvent: function handleFormEvent(t) {
                var n = this,
                    r = null,
                    i = null,
                    s = -1;
                if (t.preventDefault(), "reset" === t.type && (n.activeToggles = [], n.activeSelectors = [], n.updateControls()), n.mixer.multifilterFormEventTracker) r = n.mixer.multifilterFormEventTracker;else for (r = n.mixer.multifilterFormEventTracker = new e.MultifilterFormEventTracker(), r.form = t.target, s = 0; i = n.mixer.filterGroups[s]; s++) {
                    i.dom.form === t.target && r.totalBound++;
                }t.target === r.form && (r.totalHandled++, r.totalHandled === r.totalBound && (n.mixer.multifilterFormEventTracker = null, ("submit" === t.type || "change" === n.mixer.config.multifilter.parseOn) && n.mixer.parseFilterGroups()));
            },
            getSingleValue: function getSingleValue(e) {
                var t = this,
                    n = "",
                    r = "";
                if (e.type.match(/text|search|password/g)) {
                    if (n = e.getAttribute("data-search-attribute"), !n) throw new Error("[MixItUp] A valid `data-search-attribute` must be present on text inputs");
                    if (e.value.length < t.mixer.config.multifilter.minSearchLength) return void (t.activeSelectors = [""]);
                    r = "[" + n + '*="' + e.value + '"]';
                } else r = e.value;
                e.value && (t.activeSelectors = [r]);
            },
            getMultipleValues: function getMultipleValues(e) {
                var t = this,
                    n = [],
                    r = "",
                    i = null,
                    s = null,
                    a = -1;
                switch (e.type) {
                    case "checkbox":
                        r = 'input[type="checkbox"]';
                        break;
                    case "select-multiple":
                        r = "option";
                }
                for (s = t.dom.el.querySelectorAll(r), a = 0; i = s[a]; a++) {
                    (i.checked || i.selected) && i.value && n.push(i.value);
                }"and" === t.logic && (n = [n]), t.activeSelectors = n;
            },
            updateControls: function updateControls() {
                var e = this,
                    t = e.dom.el.querySelectorAll("[data-filter], [data-toggle]"),
                    n = null,
                    r = "filter",
                    i = -1;
                for (i = 0; n = t[i]; i++) {
                    n.getAttribute("data-toggle") && (r = "toggle"), e.updateControl(n, r);
                }
            },
            updateControl: function updateControl(e, n) {
                var r = this,
                    i = e.getAttribute("data-" + n),
                    s = "";
                s = t.getClassname(r.mixer.config.classNames, n, r.mixer.config.classNames.modifierActive), r.activeSelectors.indexOf(i) > -1 ? t.addClass(e, s) : t.removeClass(e, s);
            }
        }), e.MixerDom.registerAction("afterConstruct", "multifilter", function () {
            this.filterGroups = [];
        }), e.Mixer.registerAction("afterConstruct", "multifilter", function () {
            this.filterGroups = [], this.multifilterFormEventTracker = null;
        }), e.Mixer.registerAction("afterCacheDom", "multifilter", function () {
            var t = this,
                n = null;
            if (t.config.multifilter.enable) {
                switch (t.config.controls.scope) {
                    case "local":
                        n = t.dom.container;
                        break;
                    case "global":
                        n = t.dom.document;
                        break;
                    default:
                        throw new Error(e.messages.ERROR_CONFIG_INVALID_CONTROLS_SCOPE);
                }
                t.dom.filterGroups = n.querySelectorAll("[data-filter-group]");
            }
        }), e.Mixer.registerAction("beforeInitControls", "multifilter", function () {
            var e = this;
            e.config.multifilter.enable && (e.config.controls.live = !0);
        }), e.Mixer.registerAction("afterSanitizeConfig", "multifilter", function () {
            var e = this;
            e.config.multifilter.logicBetweenGroups = e.config.multifilter.logicBetweenGroups.toLowerCase().trim(), e.config.multifilter.logicWithinGroup = e.config.multifilter.logicWithinGroup.toLowerCase().trim();
        }), e.Mixer.registerAction("afterAttach", "multifilter", function () {
            var e = this;
            e.dom.filterGroups.length && e.indexFilterGroups();
        }), e.Mixer.registerAction("afterUpdateControls", "multifilter", function () {
            var e = this,
                t = null,
                n = -1;
            for (n = 0; t = e.filterGroups[n]; n++) {
                t.updateControls();
            }
        }), e.Mixer.registerAction("beforeDestory", "multifilter", function () {
            var e = this,
                t = null,
                n = -1;
            for (n = 0; t = e.filterGroups[n]; n++) {
                t.unbindEvents();
            }
        }), e.Mixer.extend({
            indexFilterGroups: function indexFilterGroups() {
                var t = this,
                    n = null,
                    r = null,
                    i = -1;
                for (i = 0; r = t.dom.filterGroups[i]; i++) {
                    n = new e.FilterGroup(), n.init(r, t), t.filterGroups.push(n);
                }
            },
            parseParseFilterGroupsArgs: function parseParseFilterGroupsArgs(n) {
                var r = this,
                    i = new e.UserInstruction(),
                    s = null,
                    a = -1;
                for (i.animate = r.config.animation.enable, i.command = new e.CommandFilter(), a = 0; a < n.length; a++) {
                    s = n[a], "boolean" == typeof s ? i.animate = s : "function" == typeof s && (i.callback = s);
                }return t.freeze(i), i;
            },
            parseFilterGroups: function parseFilterGroups() {
                var e = this,
                    t = e.parseFilterArgs(arguments),
                    n = e.getFilterGroupPaths(),
                    r = e.buildSelectorFromPaths(n);
                return "" === r && (r = e.config.controls.toggleDefault), t.command.selector = r, e.multimix({
                    filter: t.command
                }, t.animate, t.callback);
            },
            getFilterGroupPaths: function getFilterGroupPaths() {
                var e = this,
                    n = null,
                    _r = null,
                    i = null,
                    s = [],
                    a = [],
                    o = [],
                    l = -1;
                for (l = 0; l < e.filterGroups.length; l++) {
                    (i = e.filterGroups[l].activeSelectors).length && (s.push(i), o.push(0));
                }return n = function n() {
                    var e = null,
                        n = [],
                        r = -1;
                    for (r = 0; r < s.length; r++) {
                        e = s[r][o[r]], Array.isArray(e) && (e = e.join("")), n.push(e);
                    }n = t.clean(n), a.push(n);
                }, _r = function r(e) {
                    e = e || 0;
                    for (var t = s[e]; o[e] < t.length;) {
                        e < s.length - 1 ? _r(e + 1) : n(), o[e]++;
                    }o[e] = 0;
                }, s.length ? (_r(), a) : "";
            },
            buildSelectorFromPaths: function buildSelectorFromPaths(e) {
                var t = this,
                    n = null,
                    r = [],
                    i = "",
                    s = "",
                    a = -1;
                if (!e.length) return "";
                if ("or" === t.config.multifilter.logicBetweenGroups && (s = ", "), e.length > 1) {
                    for (a = 0; a < e.length; a++) {
                        n = e[a], i = n.join(s), r.indexOf(i) < 0 && r.push(i);
                    }return r.join(", ");
                }
                return e[0].join(s);
            }
        }), e.Facade.registerAction("afterConstruct", "multifilter", function (e) {
            this.parseFilterGroups = e.parseFilterGroups.bind(e);
        });
    };
    if (r.TYPE = "mixitup-extension", r.NAME = "mixitup-multifilter", r.EXTENSION_VERSION = "3.0.0-beta", r.REQUIRE_CORE_VERSION = "3.0.0", "object" == (typeof n === "undefined" ? "undefined" : _typeof(n)) && "object" == (typeof t === "undefined" ? "undefined" : _typeof(t))) t.exports = r;else if ("function" == typeof define && define.amd) define(function () {
        return r;
    });else {
        if (!e.mixitup || "function" != typeof e.mixitup) throw new Error("[MixItUp MultiFilter] MixItUp core not found");
        r(e.mixitup);
    }
})(window);