/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.owl-carousel').owlCarousel({
          loop: true,
          autoplay: true,
          slideTransition: 'linear',
          autoplayTimeout: 0,
          autoplaySpeed: 5000,
          autoplayHoverPause: true,
          dots: false,
          margin: 10,
          nav: true,
          navText: ["<div class='c-left'></div>", "<div class='c-right'></div>"],
          responsive: {
            0: {
              items: 2
            },
            768: {
              items: 3
            },
            1000: {
              items: 4
            }
          }
        });
        //Set minHight on all boxes
        var elements = document.getElementsByClassName('r-');
        var elementHeights = Array.prototype.map.call(elements, function(el) {
          return el.clientHeight;
        });
        var maxHeight = Math.max.apply(null, elementHeights);
        Array.prototype.forEach.call(elements, function(el) {
          el.style.height = maxHeight + "px";
        });

        $('.open-mobile-nav').on('click', function(e) {
          e.preventDefault();
          $(this).toggleClass('__open');
          $('.nav-primary').slideToggle();
          $('.nav-primary').promise().done(function(){
              if($('.nav-primary').attr('style').indexOf("none") >= 0) {
                $('.nav-primary').removeAttr('style');
              }
          });
        });



        $('.nav-primary ul li.menu-item-has-children').on('click', function(e) {
          if (e.target.tagName === 'LI') {
            $(this).find('.sub-menu').slideToggle();
          }
        });


        $('.range-filter-submenu a').on('click', function(e) {
          e.preventDefault();
        });


        $('.close-icon').on('click', function(e) {
            $('.range-filter > ul > li').not(this).find('.range-filter-submenu').slideUp();
            $(this).find('.range-filter-submenu').slideToggle();
        });

        $('.range-filter > ul > li').on('click', function(e) {
          if (e.target.nodeName === 'LI' && !$(this).hasClass('custom-range-menu')) {
            $('.range-filter > ul > li').not(this).find('.range-filter-submenu').slideUp();
            $(this).find('.range-filter-submenu').slideToggle();
          }
        });


          $('.custom-range-menu').on('click', function(e) {
              console.log('hit');
              $(this).find('ul').slideToggle();
          });

        $('.distributor-select > a').on('click', function(e) {
          e.preventDefault();
          $(this).toggleClass('__open');
          $('.distributor-select-list').slideToggle();
        });

        $('.distributor-select-list a').on('click', function(e) {
          e.preventDefault();
          var url = $(this).data('url');
          var $btn = $('.js-distributor-btn');

          $('.distributor-select-list a').removeClass('__active');
          $(this).toggleClass('__active');

          $btn.attr('href', url);
          $btn.slideDown();
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'range': {
      init: function() {

        $('li.reset-button a').on('click', function(e) {
          e.preventDefault();
          var filter_selectors = mixer.getState().activeFilter.selector.split(',').map(function(item) {
            return item.trim();
          });
          $(this).parent().parent().find('.mixitup-control-active').each(function(index, el) {
            var index = filter_selectors.indexOf($(this).data('toggle'), '');
            if (index > -1) {
              filter_selectors.splice(index, 1);
            }
          });
          if (filter_selectors.length > 0) {
            mixer.filter(filter_selectors.join());
          } else {
            mixer.filter('all');
          }
        });
        if ($('.range-select').length) {
          var mixer = mixitup('.range-select', {
            controls: {
              toggleLogic: 'and'
            },
             multifilter: {
        enable: true // enable the multifilter extension for the mixer
    }
          });
        }
      }
    },
    'recipes': {
      init: function() {

        $('li.reset-button a').on('click', function(e) {
          e.preventDefault();
          var filter_selectors = mixer.getState().activeFilter.selector.split(',').map(function(item) {
            return item.trim();
          });
          $(this).parent().parent().find('.mixitup-control-active').each(function(index, el) {
            var index = filter_selectors.indexOf($(this).data('toggle'), '');
            if (index > -1) {
              filter_selectors.splice(index, 1);
            }
          });
          if (filter_selectors.length > 0) {
            mixer.filter(filter_selectors.join());
          } else {
            mixer.filter('all');
          }
        });
        $('.range-filter-submenu a').on('click', function(e) {
          setTimeout( function(){ 
              // equal height columns
              $('.equalh:visible').matchHeight();

              $('.equalh-p:visible').matchHeight();
            }  , 850 );
        });
        if ($('.range-select').length) {
          var mixer = mixitup('.range-select', {
            controls: {
              toggleLogic: 'and'
            },
             multifilter: {
        enable: true // enable the multifilter extension for the mixer
    }
          });
        }
      }
    },
    'where_to_buy': {
      selected: [],
      init: function() {
        var obj = this;
        obj.productMixer = mixitup('.wtb-list.products > .row');
        obj.distributorMixer = mixitup('.wtb-list.distributors > .row', {
          selectors: {
            control: false
          },
          animation: {
            enable: false
          }
        });
        obj.initFilter($('.wtb-filter'));
        obj.toStepOne($('.wtb-product-preview > .btn'));

        $('.wtb-list-product a').hover(function(e) {
          obj.selectProduct($(this));
          $('.wtb-product-image').slideDown();
        });

        $('.wtb-list-product a').on('click', function(e) {
          e.preventDefault();
          obj.toStepTwo($(this));
        });

        $('.wtb-list-distributor a').on('click', function(e) {
          e.preventDefault();
          obj.selectDistributor($(this));
        });
      },
      initFilter: function(el) {
        var obj = this;
        var $btn = el.find('.wtb-filter-btn');
        var $list = el.find('.wtb-filter-list');
        var $product = $list.find('a');

        $btn.on('click', function(e) {
          e.preventDefault();
          $list.slideToggle();
          $(this).toggleClass('__open');
        });

        $product.on('click', function(e) {
          e.preventDefault();
          var title = $(this).html();
          var index = obj.selected.indexOf(title);

          if (index === -1) {
            obj.selected.push(title);
          } else {
            obj.selected.splice(index, 1);
          }

          obj.renderList();
        });
      },
      renderList: function() {
        var selected = this.selected;
        var $btn = $('.wtb-filter-btn');
        if (typeof selected !== 'undefined' && selected.length > 0) {
          var text = '';
          $.each(this.selected, function(index, val) {
            text += val;
            if (index + 1 < selected.length) {
              text += ', ';
            }
          });

          $btn.html(text);
        } else {
          $btn.html('All distributors');
        }
      },
      selectProduct: function($product) {
        var thumb_375 = $product.data('thumb-375x375');
        var thumb_220 = $product.data('thumb-220x145');
        var title = $product.html();

        $('.wtb-product-image').find('img').attr('src', thumb_375);
        $('.wtb-product-preview-img').find('img').attr('src', thumb_220);
        $('.wtb-product-image, .wtb-product-preview-img').find('.caption').html(title);
      },
      toStepTwo: function($product) {
        var distributors_filter = $product.data('distributors-filter');
        this.distributorMixer.filter(distributors_filter);
        $('.wtb').toggleClass('step_1 step_2');
        $('.wtb-product-preview').slideDown();

        this.selectDistributor($('.wtb-list-distributor:visible').first().find('a'));

      },
      toStepOne: function($el) {
        $el.on('click', function(e) {
          e.preventDefault();
          $('.wtb').toggleClass('step_1 step_2');
          $('.wtb-product-preview').slideUp();
        });
      },
      selectDistributor: function($dist) {
        var logo = $dist.data('image');
        var url = $dist.data('url');
        var description = $dist.data('description');

        $('.wtb-list-distributor a').removeClass('__active');
        $dist.addClass('__active');

        $('.wtb-distributor-logo img').attr('src', logo);
        $('.wtb-distributor-desc').html(description);
        $('.wtb-content.distributors .btn').attr('href', url);
      }
    }
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });
      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  }; 
  // Load Events
  $(document).ready(UTIL.loadEvents);



  


  /* Contact Form Raising Labels */
    document.addEventListener('DOMContentLoaded', function() {
      $(".label-inner input:not(.gform_hidden)").each(function(){
          if($(this).val() != ''){
              $(this).parents('.label-inner').removeClass('no-content');
          }
      });
      $('.label-inner input').on('blur', function(){
          if(!$(this).val()){
              $(this).parents('.label-inner').addClass('no-content');
          }
      }).on('focus', function(){
          $(this).parents('.label-inner').removeClass('no-content');
      });

      /* Change it up to work with structure of address lookup sub fields*/
        $(".label-inner-address input:not(.gform_hidden)").each(function(){
            if($(this).val() != ''){
                $(this).parents('.label-inner-address').removeClass('no-content');
            }
        });
      
      $('.label-inner-address input').on('blur', function(){
          if(!$(this).val()){
              $(this).parents('.label-inner-address').addClass('no-content');
          }
      }).on('focus', function(){
          $(this).parents('.label-inner-address').removeClass('no-content');
      });
  });



  /* Sticky aside scroll script */
  $(document).scroll(function() {
      var y = $(this).scrollTop();
      if (y > 500) {
          $('.sticky-aside').addClass('show');
      } else {
          $('.sticky-aside').removeClass('show');
      }
  });
    
    
    
    
})(jQuery); // Fully reference jQuery after this point.




/**
* jquery-match-height 0.7.2 by @liabru
* http://brm.io/jquery-match-height/
* License MIT
*/
!function(t){"use strict";"function"==typeof define&&define.amd?define(["jquery"],t):"undefined"!=typeof module&&module.exports?module.exports=t(require("jquery")):t(jQuery)}(function(t){var e=-1,o=-1,n=function(t){return parseFloat(t)||0},a=function(e){var o=1,a=t(e),i=null,r=[];return a.each(function(){var e=t(this),a=e.offset().top-n(e.css("margin-top")),s=r.length>0?r[r.length-1]:null;null===s?r.push(e):Math.floor(Math.abs(i-a))<=o?r[r.length-1]=s.add(e):r.push(e),i=a}),r},i=function(e){var o={
byRow:!0,property:"height",target:null,remove:!1};return"object"==typeof e?t.extend(o,e):("boolean"==typeof e?o.byRow=e:"remove"===e&&(o.remove=!0),o)},r=t.fn.matchHeight=function(e){var o=i(e);if(o.remove){var n=this;return this.css(o.property,""),t.each(r._groups,function(t,e){e.elements=e.elements.not(n)}),this}return this.length<=1&&!o.target?this:(r._groups.push({elements:this,options:o}),r._apply(this,o),this)};r.version="0.7.2",r._groups=[],r._throttle=80,r._maintainScroll=!1,r._beforeUpdate=null,
r._afterUpdate=null,r._rows=a,r._parse=n,r._parseOptions=i,r._apply=function(e,o){var s=i(o),h=t(e),l=[h],c=t(window).scrollTop(),p=t("html").outerHeight(!0),u=h.parents().filter(":hidden");return u.each(function(){var e=t(this);e.data("style-cache",e.attr("style"))}),u.css("display","block"),s.byRow&&!s.target&&(h.each(function(){var e=t(this),o=e.css("display");"inline-block"!==o&&"flex"!==o&&"inline-flex"!==o&&(o="block"),e.data("style-cache",e.attr("style")),e.css({display:o,"padding-top":"0",
"padding-bottom":"0","margin-top":"0","margin-bottom":"0","border-top-width":"0","border-bottom-width":"0",height:"100px",overflow:"hidden"})}),l=a(h),h.each(function(){var e=t(this);e.attr("style",e.data("style-cache")||"")})),t.each(l,function(e,o){var a=t(o),i=0;if(s.target)i=s.target.outerHeight(!1);else{if(s.byRow&&a.length<=1)return void a.css(s.property,"");a.each(function(){var e=t(this),o=e.attr("style"),n=e.css("display");"inline-block"!==n&&"flex"!==n&&"inline-flex"!==n&&(n="block");var a={
display:n};a[s.property]="",e.css(a),e.outerHeight(!1)>i&&(i=e.outerHeight(!1)),o?e.attr("style",o):e.css("display","")})}a.each(function(){var e=t(this),o=0;s.target&&e.is(s.target)||("border-box"!==e.css("box-sizing")&&(o+=n(e.css("border-top-width"))+n(e.css("border-bottom-width")),o+=n(e.css("padding-top"))+n(e.css("padding-bottom"))),e.css(s.property,i-o+"px"))})}),u.each(function(){var e=t(this);e.attr("style",e.data("style-cache")||null)}),r._maintainScroll&&t(window).scrollTop(c/p*t("html").outerHeight(!0)),
this},r._applyDataApi=function(){var e={};t("[data-match-height], [data-mh]").each(function(){var o=t(this),n=o.attr("data-mh")||o.attr("data-match-height");n in e?e[n]=e[n].add(o):e[n]=o}),t.each(e,function(){this.matchHeight(!0)})};var s=function(e){r._beforeUpdate&&r._beforeUpdate(e,r._groups),t.each(r._groups,function(){r._apply(this.elements,this.options)}),r._afterUpdate&&r._afterUpdate(e,r._groups)};r._update=function(n,a){if(a&&"resize"===a.type){var i=t(window).width();if(i===e)return;e=i;
}n?o===-1&&(o=setTimeout(function(){s(a),o=-1},r._throttle)):s(a)},t(r._applyDataApi);var h=t.fn.on?"on":"bind";t(window)[h]("load",function(t){r._update(!1,t)}),t(window)[h]("resize orientationchange",function(t){r._update(!0,t)})});
